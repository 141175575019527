import React, { useEffect, useState } from "react";
import { useNavigate, Link } from "react-router-dom";

const frontpagesidebar = (props) => {
        return (
            <ul className="list-group center">
            <li className="list-group-item"><Link to="/" className="nav-link">MIS Reports</Link></li>
            <li className="list-group-item"><Link to="/tenders-by-location/" className="nav-link">Tenders by Location</Link></li>
            <li className="list-group-item"><Link to="/tenders-by-organizations/" className="nav-link">Tenders by Organisation</Link></li>
            <li className="list-group-item"><Link to="/" className="nav-link">Tenders by Classification</Link></li>
            <li className="list-group-item"><Link to="/" className="nav-link">Tenders in Archive</Link></li>
            <li className="list-group-item"><Link to="/tenders-status/" className="nav-link">Tenders Status</Link></li>
            <li className="list-group-item"><Link to="/" className="nav-link">Cancelled/Retendered</Link></li>
            <li className="list-group-item"><Link to="/" className="nav-link">Downloads</Link></li>
            <li className="list-group-item"><Link to="/" className="nav-link">Debarment List</Link></li>
            <li className="list-group-item"><Link to="/" className="nav-link">Announcements</Link></li>
            <li className="list-group-item"><Link to="/" className="nav-link">Recognitions</Link></li>
            </ul>
        );
  }
  export default frontpagesidebar;