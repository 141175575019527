import React, { useEffect, useState } from "react";
import { useNavigate, useParams } from "react-router-dom";
import { axiosRequest } from "../../api";

const MyXbids = (props) => {

  const token = sessionStorage.getItem('token');
  const [users, setData] = useState([]);
  const user = sessionStorage.getItem('user');
  const navigate = useNavigate();

  var udata = JSON.parse(user);
  console.log(udata[0]);
  var companyid = udata[0].company_user.id;
  var userid = 1;

  if (token.exp * 1000 < Date.now()) {
    console.log('Expired');
  }

  const config = {
    headers: { Authorization: `Bearer ${token}`, userid: `${userid}` }
};
const data = {
}

  useEffect(() => {
    axiosRequest.get('bids/company/expired/'+companyid, data, config)
      .then(function (response){
        setData(response.data)
        console.log(response);
      })
      .catch(function (error) {
        if(error.response.data.message=='Invalid Token')
        {
            navigate("/logout");
        }
      });
  }, []);

  const tenderSearch = (event) => {
    if(event.target.value!='')
    {
      console.log('tenders/user/'+userid+'&q='+event.target.value);
        axiosRequest.get('tenders/user/'+userid+'?q='+event.target.value, data, config)
          .then(function (response){
            setData(response.data)
            console.log(response);
          })
          .catch(function (error) {
            if(error.response.data.message=='Invalid Token')
            {
                navigate("/logout");
            }
          });
    }
    else
    {
        axiosRequest.get('tenders/user/'+userid, data, config)
          .then(function (response){
            setData(response.data)
            console.log(response);
          })
          .catch(function (error) {
            if(error.response.data.message=='Invalid Token')
            {
                navigate("/logout");
            }
          });
    }

  }

  const usrView = (e) => {
    navigate("/bids/view/"+e);
    window.location.pathname = '/bids/view/'+e;
  }

  const formatDate = (dateString) => {
    const date = new Date(dateString);
    return date.toLocaleString('en-US', {
      year: 'numeric',
      month: 'long',
      day: 'numeric',
      hour: 'numeric',
      minute: 'numeric',
      second: 'numeric',
      hour12: true
    });
  };
    return (
            <table className="table table-striped table-bordered">
                <thead>
                    <tr>
                        <th>title</th>
                        <th>Created Date</th>
                        <th>Expired On</th>
                        <th></th>
                    </tr>
                </thead>
                <tbody>
                    {users && users.map(user =>
                        <tr key={user.id}>
                            <td>{user.cover}</td>
                            <td>{user.createdAt}</td>
                            <td>{formatDate(user.bid_tender.bid_opening_date)}</td>
                            <td>
                            <a href="#" onClick={usrView.bind(this, user.id)}>View</a>
                            </td>
                        </tr>
                    )}
                </tbody>
            </table>
    );
  }
  export default MyXbids;