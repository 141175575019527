import React, { useEffect, useState } from "react";
import { Link, useNavigate, useParams } from "react-router-dom";
import { Col, Container, Row, Button } from "react-bootstrap";
import { axiosRequest } from "../../api";
import Loader from '../App/loader';

const Favtenders = (props) => {
  const token = sessionStorage.getItem('token');
  const [users, setData] = useState([]);
  const [tenders, setTenders] = useState([]); // Ensure users is initialized as an array
  const user = sessionStorage.getItem('user');
  const [favoriteTenders, setFavoriteTenders] = useState([]);
  const navigate = useNavigate();
  const [isLoader, setLoader] = useState(false);
  const [refreshKey, setRefreshKey] = useState(0);
  const [searchQuery, setSearchQuery] = useState("");
  const [noTendersFound, setNoTendersFound] = useState(false);
  const [pcategories, setPCategories] = useState([]);
  const [categories, setCategories] = useState([]);
  const [types, setTypes] = useState([]);
  const [pcatValue, setPCatValue] = useState('');
  const [catValue, setCatValue] = useState('');
  const [typeValue,   setTypeValue] = useState('');

  var udata = JSON.parse(user);
  var userid = udata[0].id;

  if (token.exp * 1000 < Date.now()) {
    console.log('Expired');
  }

  const config = {
    headers: { Authorization: `Bearer ${token}`, userid: `${userid}` }
};
const data = {
}

const handleSearchChange = (e) => {
  setSearchQuery(e.target.value);
};

const handleSearchSubmit = async (e) => {
  e.preventDefault();
  setLoader(true);
  setNoTendersFound(false); 
  try {
    console.log(`/tenders/search?location=${searchQuery}`);
    const response = await axiosRequest.get(`/tenders/search?location=${searchQuery}`, config);
    console.log(response.data)
    const tenders = Array.isArray(response.data) ? response.data : [];
    if (tenders.length > 0) {
      setTenders(tenders);
      setNoTendersFound(false);
    } else {
      setTenders([]);
      setNoTendersFound(true);
    }
  } catch (error) {
    console.error("Error fetching tenders:", error);
    setTenders([]); // Clear previous results
    setNoTendersFound(true); // Display "No tenders found" message
  } finally {
    setLoader(false);
  }
};

  useEffect(() => {
    setLoader(true);
    console.log('tenders/favorite/'+userid);
    axiosRequest.get('tenders/favorite/'+userid, config)
      .then(function (response){
        console.log(response)
        setData(response.data)
        setLoader(false);
      })
      .catch(function (error) {
        if(error.response.data.message=='Invalid Token')
        {
            navigate("/logout");
        }
      });
  }, [refreshKey]);

  const usrView = (e) => {
    navigate("/tender/details/"+e);
    window.location.pathname = '/tender/details/'+e;
  }

  const thsyle = {
    fontSize: "14px",
    fontFamily: "tahoma"
  };

  const tdsyle = {
    fontSize: "12px",
    fontFamily: "tahoma"
  };

  const usrRemove = (e) => {

    const choice = window.confirm(
        "Do you really want to remove this tender from favourite list?"
      );
    if (choice) {
        axiosRequest.post('tenders/favorite/remove-favorite/'+e, data, config)
        .then(function (response){
            setRefreshKey(oldKey => oldKey +1)
            setLoader(false);
        })
        .catch(function (error) {
            if(error.response.data.message=='Invalid Token')
            {
                navigate("/logout");
            }
        });
    }
  }

  const formatDate = (dateString) => {
    const date = new Date(dateString);
    return date.toLocaleDateString('en-US', {
      year: 'numeric',
      month: 'long',
      day: 'numeric'
    });
  };

  const isFavorite = (tenderId) => {
    return users.some(fav => fav.id === tenderId);
  };

  // Add or Remove from favorite
  const toggleFavorite = async (tenderId) => {
    const isAlreadyFavorite = isFavorite(tenderId);

    try {
      setLoader(true);
      if (isAlreadyFavorite) {
        // Remove from favorite
        await axiosRequest.post(`tenders/favorite/remove-favorite/${tenderId}`, {}, config);
      } else {
        // Add to favorite
        await axiosRequest.post(`tenders/favorite/add/${tenderId}`, {}, config);
      }
      // Refresh the favorites list
      setRefreshKey(oldKey => oldKey + 1);
    } catch (error) {
      console.error("Error toggling favorite status:", error);
      if (error.response?.data?.message === 'Invalid Token') {
        navigate("/logout");
      }
    } finally {
      setLoader(false);
    }
  };


    return (
      <div className="main">
        <Row>
        <div className="searchbox2">
          <form onSubmit={handleSearchSubmit}>
            <table style={{textAlign: "left"}}>
                <tr>
                    <td>
                    <label>Tender Status: </label>
                    </td>
                    <td>
                    <select className="form-control " style={{ display: "inline-block", fontSize: "14px" }}>
                        <option>- Select- </option>
                        <option>To Be Opened</option>
                        <option>Technical Bid Opening</option>
                        <option>Financial Bid Opening</option>
                        <option>Awarded</option>
                        <option>Cancelled</option>
                    </select>
                </td>
                <td>
                    <label>Keyword: </label>
                    </td>
                    <td>
                    <input
                    type="text"
                    placeholder="Keyword"
                    className="me-2 form-control"
                    value={searchQuery}
                    onChange={handleSearchChange}
                    style={{width:"250px"}}
                    />
                </td>
            </tr>
            <tr>
                    <td>
                    <label>Tender Category: </label>
                    </td>
                    <td>
                    <select className="form-control selectpicker" name="category" onChange={(e) => setCatValue(e.target.value)} value={catValue}>
                    <option value="">- Select Category -</option>
                    {categories && categories.map(category =>
                      <option value={category.id}>{category.name}</option>
                    )}
                    </select>
                </td>
                <td>
                <label>Product Category: </label>
                </td>
                    <td>
                    <select className="form-control selectpicker" name="pcategory" onChange={(e) => setPCatValue(e.target.value)} value={pcatValue}>
                    <option value="">- Select Product Category -</option>
                    {pcategories && pcategories.map(pcategory =>
                      <option value={pcategory.id}>{pcategory.name}</option>
                    )}
                    </select>
                </td>
            </tr>
            <tr>
                    <td>
                    <label>Tender Type: </label>
                    </td>
                    <td>
                    <select className="form-control selectpicker" name="pcategory" onChange={(e) => setTypeValue(e.target.value)} value={typeValue}>
                    <option value="">- Select Type -</option>
                    {types && types.map(type =>
                      <option value={type.id}>{type.name}</option>
                    )}
                    </select>
                </td>
                <td>
                </td>
                    <td>
                    
                </td>
            </tr>
            </table>
            <br />
            <input
              type="submit"
              value="Submit"
              className="btn btn-primary btn-sm float-end"
            />
          </form>
        </div>
      </Row>
      <Row>
        <Col sm={12}>
          <br /><br />
          {noTendersFound ? (
            <div>No tenders found for the specified location.</div>
          ) : (
            tenders.length > 0 && (
            <table className="table table-striped table-bordered">
              <thead style={thsyle}>
                <tr>
                  <th>e-Published Date</th>
                  <th>Bid Submission Closing Date</th>
                  <th>Bids Opening On</th>
                  <th colSpan={2}>Title/Ref.No./Tender Id</th>
                  <th>Organization Name</th>
                  <th></th>
                </tr>
              </thead>
              <tbody style={tdsyle}>
                {tenders.map(user => (
                  <tr key={user.id}>
                    <td>{formatDate(user.published_date)}</td>
                    <td>{formatDate(user.bid_submission_closing_date)}</td>
                    <td>{formatDate(user.bid_opening_date)}</td>
                    <td colSpan={2}>
                      <a href="#" onClick={() => usrView(user.id)}>
                        {user.title}
                      </a> / 
                    </td>
                    <td>{user.tender_company.name}</td>
                    <td>
                      <a
                        href="#"
                        onClick={() => toggleFavorite(user.id)}
                      >
                        {isFavorite(user.id) ? "Remove from Favorite" : "Add to Favorite"}
                      </a>
                    </td>

                  </tr>
                ))}
              </tbody>
            </table>
             )
          )}
        </Col>
      </Row>
      <Row>
      <strong class="pgheading">Favorite Tenders</strong>
      <br /><br />
            <table className="table table-striped table-bordered">
                <thead>
                    <tr>
                        <th>id</th>
                        <th>title</th>
                        <th></th>
                    </tr>
                </thead>
                <tbody>
                    {users && users.map(user =>
                        <tr key={user.id}>
                            <td>{user.id}</td>
                            <td>
                              <table>
                                <tr>
                                    <td colSpan={4}>
                                        <a href="#" onClick={usrView.bind(this, user.id)}>{user.title}</a>
                                    </td>
                                </tr>
                                <tr>
                                    <td>
                                        <strong>Bid Submission Start Date</strong><br />
                                        {formatDate(user.bid_submission_start_date)}</td>
                                    <td>
                                        <strong>Bid Submission Closing Date</strong><br />
                                        {formatDate(user.bid_submission_closing_date)}
                                    </td>
                                </tr>
                              </table>
                              </td>
                            <td>
                                <a href="#" onClick={usrRemove.bind(this, user.id)}>Remove</a> 
                            </td>
                        </tr>
                    )}
                    { 
                        isLoader &&
                            <tr><td><Loader></Loader></td></tr>
                    }
                </tbody>
            </table>
            </Row>
            </div>
    );
  }
  export default Favtenders;