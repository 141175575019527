import React, { useEffect, useState } from "react";
import { useNavigate,useParams } from "react-router-dom";
import {Alert } from "react-bootstrap";
import { axiosRequest } from "../../api";
import Loader from '../App/loader';

const Edittender = (props) => {
  const token = sessionStorage.getItem('token');
  const navigate = useNavigate();
  const user = sessionStorage.getItem('user');
  const [tender, setTender] = useState([]);
  const [isLoader, setLoader] = useState(false);
  const [states, setState] = useState([]);
  const [stateValue, setStateValue] = useState('');
  const [cityValue, setCityValue] = useState('');
  const [cities, setCity] = useState([]);
  const [pcategories, setPCategories] = useState([]);
  const [categories, setCategories] = useState([]);
  const [types, setTypes] = useState([]);
  const [pcatValue, setPCatValue] = useState('');
  const [catValue, setCatValue] = useState('');
  const [ptypeValue, setPTypeValue] = useState('');
  const [isError, setError] = useState(false);
  const [err, setERR] = useState("");
  const [isSuccess, setSuccess] = useState(false);

  var udata = JSON.parse(user);
  var userid = udata[0].id;
  var tenderid;

  if (token.exp * 1000 < Date.now()) {
    console.log('Expired');
  }

  var {id} = useParams();
  if(!id)
  {
    id = 1;
  } 
  const config = {
    headers: { Authorization: `Bearer ${token}`, userid: `${userid}` }
};
const data = {
}

useEffect(() => {
    setLoader(true);
      axiosRequest.get('/tenders/view/'+id, data, config)
        .then(function (response){
            setTender(response.data)
            setPCatValue(response.data.product_category);
            loadCities(response.data.tenders_state.id)
            setCatValue(response.data.category);
            console.log(response.data.city)
            setCityValue(response.data.city);
            setPTypeValue(response.data.tender_type.id);
            setStateValue(response.data.tenders_state.id);
            //setCityValue(response.data.tenders_city.id);
        })
      axiosRequest.get('location/states', data, config)
          .then(function (response){
            setState(response.data)
          })
      axiosRequest.get('tenders/productcategories', data, config)
          .then(function (response){
            setPCategories(response.data)
          })
      axiosRequest.get('tenders/categories', data, config)
          .then(function (response){
            setCategories(response.data)
          })
          axiosRequest.get('tenders/types', data, config)
          .then(function (response){
            setTypes(response.data)
          })

        setLoader(false);
    }, []);

    const loadCities = (event) => {
    setStateValue(event);
    axiosRequest.get('location/states/cities/'+event, data, config)
          .then(function (response){
            setCity(response.data)
          })
    }    

const formSubmit = async (e) => {
    e.preventDefault();
    setError(false);
    setSuccess(false);
    setLoader(true);
    console.log(tender);
    var err = 0;
    var list='';
    tender.category = catValue;
    tender.product_category = pcatValue;
    tender.type = ptypeValue
    tender.city = cityValue;
    tender.state = stateValue;

    if(tender.title === undefined && tender.description === undefined && tender.bid_opening_date === undefined && tender.tender_fee  === undefined && tender.bid_submission_start_date  === undefined && tender.bid_submission_closing_date  === undefined)
    {
        list+="Fill all details.\n"
        err = 1
    }
    if(isNaN(tender.tender_value))
    {
        err =1;
        list+="Tender Value must be a number.\n";
        setError(true);
    }

    var closedate = tender.bid_submission_closing_date.split('/');
    var startdate = tender.bid_submission_start_date.split('/');
        //Create a date object from the arrays
    var closedate1 = Date.parse(closedate[0], closedate[1] - 1, closedate[2]);
    var startdate1 = Date.parse(startdate[0], startdate[1] - 1, startdate[2]);
    
    if(closedate1<startdate1)
    {
        err =1;
        list+="Bid Submission End date cannot be earlier than Bid Submission Start date.\n";
        setError(true);   
    }
    if(isNaN(tender.tender_fee))
    {
        err =1;
        list+="Tender Fee must be a number.\n";
        setError(true);
    }

    if(err===0)
    {
        axiosRequest.post('tenders/update/'+id, tender, config)
       .then(function (response){
        if (response.data.msg === 'Success') {
            console.log(response.data);
            setERR('Successfuly Update The Tender.');
            setSuccess(true);
            setTender(tender);
        }
        else
        {
            setERR("Error in updating the tender.");
            setError(true);
        }
        console.log(response.data);
        setLoader(false);
      })
      .catch(function (error) {
        if(error.response.data.message ==='Invalid Token')
        {
            navigate("/logout");
        }
      });
    }
    else
    {
        console.log(list);
        setERR(""+list+"");
        setError(true);
        setLoader(false);
    }
}
    return (
            <div className="main">
                    <form onSubmit={formSubmit}>
            <table className="table table-striped table-bordered">
                <tbody>
                        <tr>
                            <td><strong>Title</strong></td>
                            <td colSpan={5}><input 
                            type="text" 
                            className="form-control"
                            id="title"
                            name="title"
                            value={tender.title}
                            onChange={(e) => {
                              setTender({...tender, title: e.target.value})
                             }}
                            /></td>
                        </tr>
                        <tr>
                            <td><strong>Description</strong></td>
                            <td colSpan={5}><textarea
                            className="form-control"
                            id="description"
                            name="description"
                            value={tender.description}
                            onChange={(e) => {
                              setTender({...tender, description: e.target.value})
                             }}
                            >
                            </textarea></td>
                        </tr>
                        <tr>
                            <td><strong>Category</strong></td>
                            <td colSpan={2}>
                            <select className="form-control selectpicker" name="category" onChange={(e) => setCatValue(e.target.value)} value={catValue}>
                                <option value="">- Select Category -</option>
                                {categories && categories.map(category =>
                                <option value={category.id}>{category.name}</option>
                                )}
                                </select>
                            </td>
                        <td><strong>Product Category</strong></td>
                            <td colSpan={2}>
                            <select className="form-control selectpicker" name="pcategory" onChange={(e) => setPCatValue(e.target.value)} value={pcatValue}>
                                <option value="">- Select Product Category -</option>
                                {pcategories && pcategories.map(pcategory =>
                                <option value={pcategory.id}>{pcategory.name}</option>
                                )}
                                </select>
                            </td>
                        </tr>
                        <tr>   
                            <td><strong>Tender Type</strong></td>
                            <td colSpan={2}>
                            <select className="form-control selectpicker" name="pcategory" onChange={(e) => setPTypeValue(e.target.value)} value={ptypeValue}>
                                <option value="">- Select Tender Type -</option>
                                {types && types.map(type =>
                                <option value={type.id}>{type.name}</option>
                                )}
                                </select>
                            </td>
                            <td colSpan={3}></td>
                        </tr>
                        <tr>
                            <td><strong>State</strong></td>
                            <td colSpan={2}>
                                <select className="form-control selectpicker" name="state" onChange={(e) => loadCities(e.target.value)} value={stateValue}>
                                    <option value="">- Select State -</option>
                                    { states && states.map(state =>                      
                                    <option value={state.id}> {state.name}</option>
                                    )}
                                </select>
                            </td>
                            <td><strong>City</strong></td>
                            <td colSpan={2}><select className="form-control selectpicker" name="city" onChange={(e) => setCityValue(e.target.value)} value={cityValue}>
                                    <option value="">- Select Cities -</option>
                                    { cities && cities.map(city =>                      
                                    <option value={city.id}> {city.name}</option>
                                    )}
                                </select>
                            </td>
                        </tr>
                        <tr>
                        <td><strong>Pin Code</strong></td>
                        <td colSpan={2}><input 
                            type="text" 
                            className="form-control"
                            id="pincode"
                                name="pincode"
                                value={tender.pincode}
                                onChange={(e) => {
                                setTender({...tender, pincode: e.target.value})
                            }}
                            />
                            </td>
                            <td colSpan={3}></td>
                        </tr>
                        <tr>
                            <td><strong>Meeting Address</strong></td>
                            <td colSpan={5}>
                                <textarea
                                className="form-control"
                                id="meeting_address"
                                name="meeting_address"
                                value={tender.meeting_address}
                                onChange={(e) => {
                                setTender({...tender, meeting_address: e.target.value})
                                }}
                                >
                                </textarea>
                            </td>
                        </tr>
                        <tr>
                            <td><strong>is NDA Allowed</strong></td>
                            <td colSpan={2}> 
                                <select className="form-control selectpicker" name="nda_allowed" onChange={(e) => { setTender({...tender, nda_allowed: e.target.value}) }} value={tender.nda_allowed}>
                                    <option value="">- Select -</option>
                                    <option value="1">Yes</option>
                                    <option value="0">No</option>
                                </select>
                            </td>
                            <td colSpan={3}> 
                            </td>
                        </tr>
                        <tr>
                            <td><strong>Tender Value</strong></td>
                            <td colSpan={2}>
                                <input 
                                type="text" 
                                className="form-control"
                                id="tender_value"
                                name="tender_value"
                                value={tender.tender_value}
                                onChange={(e) => {
                               setTender({...tender, tender_value: e.target.value})
                             }}
                                />    
                            </td>
                            <td><strong>Tender Fee</strong></td>
                            <td colSpan={2}>
                                <input 
                                type="text" 
                                className="form-control"
                                id="tender_fee"
                                name="tender_fee"
                                value={tender.tender_fee}
                                onChange={(e) => {
                               setTender({...tender, tender_fee: e.target.value})
                             }}
                                />    
                            </td>
                        </tr>
                        <tr>
                            <td><strong>Fee Payable To</strong></td>
                            <td colSpan={2}>
                                <input 
                                type="text" 
                                className="form-control"
                                id="fee_payable_to"
                                name="fee_payable_to"
                                value={tender.fee_payable_to}
                                onChange={(e) => {
                               setTender({...tender, fee_payable_to: e.target.value})
                             }}
                                />    
                            </td>
                            <td><strong>Fee Payable At</strong></td>
                            <td colSpan={2}>
                                <input 
                                type="text" 
                                className="form-control"
                                id="fee_payable_at"
                                name="fee_payable_at"
                                value={tender.fee_payable_at}
                                onChange={(e) => {
                               setTender({...tender, fee_payable_at: e.target.value})
                             }}
                                />    
                            </td>
                        </tr>
                        <tr>
                            <td><strong>Technical Evaluation Allowed</strong></td>
                            <td colSpan={2}>
                                <select className="form-control selectpicker" name="technical_evaluation_allowed" onChange={(e) => { setTender({...tender, technical_evaluation_allowed: e.target.value}) }} value={tender.technical_evaluation_allowed}>
                                    <option value="">- Select -</option>
                                    <option value="Yes">Yes</option>
                                    <option value="No">No</option>
                                </select>
                            </td>
                            <td><strong>Payment Mode</strong></td>
                            <td colSpan={2}>
                                <input 
                                type="text" 
                                className="form-control"
                                id="payment_mode"
                                name="payment_mode"
                                value={tender.payment_mode}
                                onChange={(e) => {
                                setTender({...tender, payment_mode: e.target.value})
                                }}
                                />    
                            </td>
                        </tr>
                        <tr>
                            <td><strong>EMD Amount</strong></td>
                            <td colSpan={2}>
                                <input 
                                type="text" 
                                className="form-control"
                                id="emd_amount"
                                name="emd_amount"
                                value={tender.emd_amount}
                                onChange={(e) => {
                                setTender({...tender, emd_amount: e.target.value})
                                }}
                                />    
                            </td>
                            <td><strong>EMD Fee Type</strong></td>
                            <td colSpan={2}>
                                <input 
                                type="text" 
                                className="form-control"
                                id="emd_fee_type"
                                name="emd_fee_type"
                                value={tender.emd_fee_type}
                                onChange={(e) => {
                                setTender({...tender, emd_fee_type: e.target.value})
                                }}
                                />    
                            </td>
                        </tr>
                        <tr>
                            <td><strong>EMD Payable To</strong></td>
                            <td colSpan={2}>
                                <input 
                                type="text" 
                                className="form-control"
                                id="emd_payble_to"
                                name="emd_payble_to"
                                value={tender.emd_payble_to}
                                onChange={(e) => {
                                setTender({...tender, emd_payble_to: e.target.value})
                                }}
                                />    
                            </td>
                            <td><strong>EMD Payable At</strong></td>
                            <td colSpan={2}>
                                <input 
                                type="text" 
                                className="form-control"
                                id="emd_payble_at"
                                name="emd_payble_at"
                                value={tender.emd_payble_at}
                                onChange={(e) => {
                                setTender({...tender, emd_payble_at: e.target.value})
                                }}
                                />    
                            </td>
                        </tr>
                        <tr>
                        <td colSpan={6}><strong>Dates</strong></td>
                        </tr>    
                        <tr>
                            <td colSpan={2}><strong>Bid Submission Start Date</strong></td>
                            <td>
                                <input 
                                type="datetime-local"
                                className="form-control"
                                id="bid_submission_start_date"
                                name="bid_submission_start_date"
                                value={(tender.bid_submission_start_date || '').toString().substring(0, 16)}
                                onChange={(e) => {
                                setTender({...tender, bid_submission_start_date: e.target.value})
                                }}
                                />    
                            </td>
                            <td colSpan={2}><strong>Bid Submission Closing Date</strong></td>
                            <td>
                                <input 
                                type="datetime-local"
                                className="form-control"
                                id="bid_submission_closing_date"
                                name="bid_submission_closing_date"
                                value={(tender.bid_submission_closing_date || '').toString().substring(0, 16)}
                                onChange={(e) => {
                                setTender({...tender, bid_submission_closing_date: e.target.value})
                                }}
                                />    
                            </td>
                        </tr>
                        <tr>
                            <td colSpan={2}><strong>Bid Opening Date</strong></td>
                            <td>
                                <input 
                                type="datetime-local"
                                className="form-control"
                                id="bid_opening_date"
                                name="bid_opening_date"
                                value={(tender.bid_opening_date || '').toString().substring(0, 16)}
                                onChange={(e) => {
                                setTender({...tender, bid_opening_date: e.target.value})
                                }}
                                />    
                            </td>
                            <td colSpan={3}></td>
                        </tr>
                        <tr>
                        <td colSpan={6}><button className="btn btn-primary float-end">Update</button></td>
                        </tr> 
                        <tr>
                            <td colSpan={6}>
                            { 
                                isLoader &&
                                    <Loader></Loader>
                            }
                            {
                                isSuccess &&
                                    <Alert variant="success">{err}</Alert>
                            }
                            {
                            isError &&
                                    <Alert variant="danger">{err}</Alert>
                            }

                            </td>
                        </tr>
                </tbody>
            </table>
            </form>
            </div>
    );
  }
  export default Edittender;