import React, { useEffect, useState } from "react";
import { useNavigate, useParams, Link } from "react-router-dom";
import { Button, Col, Container, Form, Row, Alert } from "react-bootstrap";
import { axiosRequest } from "../../api";
import FileUpload from '../App/FileUpload';
import Loader from '../App/loader';

const PostBids = (props) => {
  const token = sessionStorage.getItem('token');
  const navigate = useNavigate();
  const user = sessionStorage.getItem('user');
  const [data, setUser] = useState([]);
  const [city, setCity] = useState([]);
  const [cat, setCat] = useState([]);
  const [company, setCompany] = useState([]);
  const [isOwner, setisOwner] = useState(false);
  const [bidsurl, setBidsURL] = useState(false);
  const [islogin, setLogin] = useState(false);
  const [cover, setCover] = useState("");
  const [isLoader, setLoader] = useState(false);
  const [isError, setError] = useState(false);
  const [err, setERR] = useState("");
  const [isSuccess, setSuccess] = useState(false);
  const [step, setStep] = useState(1);
  const [termsAccepted, setTermsAccepted] = useState(false);
  const [errorMessage, setErrorMessage] = useState('');


  var userid = 0;  

  if(user)
  {
    var udata = JSON.parse(user);
    userid = udata[0].id;
  }
  var {id} = useParams();
  if(!id)
  {
    id = 1;
  }
  const config = {
    headers: { Authorization: `Bearer ${token}`, userid: `${userid}` }
};

const [filePaths, setFilePaths] = useState({
  technical: null,
  financial: null,
});

const [contactdetails, setContactdetails] = useState({
  name: null,
  email: null,
  phone: null,
  address: null,
});
const handleChange = (e) => {
  const { name, value } = e.target;
  setContactdetails((prevDetails) => ({
    ...prevDetails,
    [name]: value
  }));
};

const handleUploadComplete = (fileKey, result) => {
  if (result) {
    setFilePaths(prevPaths => ({
      ...prevPaths,
      [fileKey]: result,
    }));
  } else {
    console.log(`Upload for ${fileKey} failed.`);
  }
};

const userdata = {id}

useEffect(() => {
  setLoader(true);
  if(user)
  {
    setLogin(true);
  }
  if(id)
  {
    axiosRequest.get('/tenders/view/'+id, userdata, config)
      .then(function (response){
        setCity(response.data.tenders_city.name);
        setCat(response.data.tenders_categories.name);
        setUser(response.data);
        setBidsURL('/tenders/bids/'+id);
        if(response.data.userid == userid)
        {
          setisOwner(true);
        }

      axiosRequest.get('/company/user/'+userid, userdata, config)
      .then(function (response){
        setCompany(response.data);
      })

        setLoader(false);
      })
      .catch(function (error) {
        console.log(error);
      });
  }
}, [])

useEffect(() => {
  if (company && company.company_city && company.company_state) {
    setContactdetails({
      name: udata[0].first_name + ' ' + udata[0].last_name,
      phone: udata[0].phone,
      email: udata[0].email,
      address: `${company.registered_address}\n${company.company_city.name}\n${company.company_state.name}\nPostal Code: ${company.postal_code}`
    });
  }
}, [company]);

function handleSubmit(event) {
    setLoader(true);
    setError(false);
    setSuccess(false);
    event.preventDefault()

    if(filePaths.technical!='' && filePaths.financial)
    {

    const formData = new FormData();
    formData.append('technical', filePaths.technical);
    formData.append('financial', filePaths.financial);
    formData.append('name', contactdetails.name);
    formData.append('email', contactdetails.email);
    formData.append('phone', contactdetails.phone);
    formData.append('address', contactdetails.address);
    formData.append('cover', cover);
    const config = {
      headers: {
        'content-type': 'multipart/form-data',
        Authorization: `Bearer ${token}`, 
        userid: `${userid}`
      },
    };

    axiosRequest.post('/bids/tender/post/'+id, formData, config)
    .then(function (response){
             if(response.status==200)
              {
                  if (response.data.msg == 'Success') {
                    setERR('Posted your bid successfully.');
                    setSuccess(true);
                  }
                  else
                  {
                    setERR("Error in Submitting your proposal.");
                    setError(true);
                  }
              }
              else
              {
                setERR("Error in Submitting your proposal.");
                setError(true);
              }
        setLoader(false);
    })
      .catch(function (error) {
        setERR(error.response.data.msg);
        setError(true);
      });
    }
    else
    {
        setERR("You forgot to select proposal documents.");
        setError(true);
        setLoader(false);
    }
  }

  const handleProceed = () => {
    setStep(2);
  };

  const handleTermsChange = (e) => {
    setTermsAccepted(e.target.checked);
    if (e.target.checked) {
      setErrorMessage('');
    }
  };

  const handleNext = () => {
    if (!termsAccepted) {
      setErrorMessage('You should accept terms and conditions before making a bid.');
    } else {
      setStep(3);
    }
  };

  const handleContact = () => {
    setStep(4);
  };

  const handleBack = () => {
    setStep(step-1);
  };

  document.title = data.title+" | ConnectIND"; 
    return (
            <div>
              {step === 1 && (
                <div>
                <h3 className="theading">Tender Details</h3>
                <table className="tenders">
                <tbody>
                    <tr><td><strong>Title</strong></td><td colSpan={3}>{data.title}</td></tr>
                    <tr><td><strong>Work Description</strong></td><td colSpan={3}>{data.description}</td></tr>
                    <tr><td><strong>Tender Value</strong></td><td colSpan={3}>₹{data.tender_value}</td></tr>
                    <tr>
                      <td><strong>Type:</strong></td><td>{data.type}</td>
                      <td><strong>Category:</strong></td><td>{cat}</td>
                    </tr>
                    <tr>
                      <td><strong>Location:</strong></td><td>{city}</td>
                      <td><strong>Pin Code:</strong></td><td>{data.pincode}</td>
                    </tr>
                    <tr>
                      <td><strong>Bid Submission Start Date:</strong></td><td>{data.bid_submission_start_date}</td>
                      <td><strong>Bid Submission End Date:</strong></td><td>{data.bid_submission_closing_date}</td>
                    </tr>
                </tbody>
                </table>
                <h3 className="theading">Tender Fee Details</h3>
                <table className="tenders">
                <tbody>
                    <tr>
                      <td><strong>Tender Fee:</strong></td><td>₹{data.tender_fee}</td>
                      <td></td><td></td>
                    </tr>
                    <tr>
                      <td><strong>Fee Payable To:</strong></td><td>{data.fee_payable_to}</td>
                      <td><strong>Fee Payable At:</strong></td>{data.fee_payable_at}<td></td>
                    </tr>
                    <tr>
                      <td><strong>EMD Amount:</strong></td><td>₹{data.emd_amount}</td>
                      <td><strong>Emd Fee Type</strong></td>{data.emd_fee_type}<td></td>
                    </tr>
                    <tr>
                      <td><strong>EMD Fee Payable To:</strong></td><td>{data.emd_payble_to}</td>
                      <td><strong>EMD Fee Payable At:</strong></td>{data.emd_payble_at}<td></td>
                    </tr>
                </tbody>
                </table>
                <button onClick={handleProceed} className="btn btn-primary">Proceed to make a bid</button>
                </div>
                )}
                {step === 2 && (
                   <div>
                   <h3 className="theading">Terms and Conditions</h3>
                   <textarea readOnly rows="10" cols="50">
                     Please read the terms and conditions carefully before submitting your bid.
                     By accepting, you agree to abide by all the rules and regulations stated.
                     [Insert Terms and Conditions Here]
                   </textarea>
                   <br />
                   <label>
                     <input
                       type="checkbox"
                       checked={termsAccepted}
                       onChange={handleTermsChange}
                     />  
                     I accept the terms and conditions
                   </label>
                   <br />
                   {errorMessage && <p style={{ color: 'red' }}>{errorMessage}</p>}
                   <button type="button" className="btn btn-primary" onClick={handleBack}>Back</button> &nbsp; &nbsp;
                   <button onClick={handleNext} className="btn btn-primary">Next</button>
                 </div>
                )}
                {step === 3 && (
                   <div>
                   <h3 className="theading">Company Details</h3>
                   <table className="profiletable">
                <tbody>
                    <tr><td>Name</td><td colSpan={3}>{company.name}</td></tr>
                    <tr><td>Registration Number</td><td colSpan={3}>{company.registration_number}</td></tr>
                    <tr><td>Registered Address</td><td colSpan={3}>{company.registered_address}</td></tr>
                    <tr><td>City</td><td>{company.company_city.name}</td><td>State</td><td>{company.company_state.name}</td></tr>
                    <tr><td>Postal Code</td><td colSpan={3}>{company.postal_code}</td></tr>
                    <tr><td>Establishment Year</td><td>{company.establishment_year}</td><td>Nature of Business</td><td>{company.nature_of_business}</td></tr>
                    <tr><td>Legal Status</td><td>{company.legal_status}</td><td>Category</td><td>{company.category}</td></tr>
                    <tr><td>PAN</td><td>{company.pan}</td><td>GST Number</td><td>{company.gst}</td></tr>
                    <tr></tr>
                </tbody>
                </table>
                <h3 className="theading">Contact Details</h3>
                <table className="profiletable">
                <tbody>
                    <tr>
                      <td>Contact Name</td>
                      <td colSpan={3}>
                          <input 
                            type="text" 
                            className="form-control"
                            id="title"
                            name="title"
                            value={contactdetails.name}
                            onChange={handleChange}
                            />
                        </td>
                    </tr>
                    <tr>
                      <td>Correspondence Email</td>
                      <td colSpan={3}>
                        <input 
                            type="text" 
                            className="form-control"
                            id="title"
                            name="title"
                            value={contactdetails.email}
                            onChange={handleChange}
                            />
                      </td>
                    </tr>
                    <tr>
                      <td>Phone</td>
                      <td colSpan={3}>
                        <input 
                            type="text" 
                            className="form-control"
                            id="title"
                            name="title"
                            value={contactdetails.phone}
                            onChange={handleChange}
                            />
                      </td>
                    </tr>
                    <tr>
                      <td>Registered Address</td>
                      <td colSpan={3}>
                      <textarea
                          className="form-control"
                          id="address"
                          name="address"
                          value={contactdetails.address}
                          onChange={handleChange}
                          rows="6"
                        />
                            </td>
                    </tr>
                    <tr></tr>
                </tbody>
                </table>
                   <br />
                   {errorMessage && <p style={{ color: 'red' }}>{errorMessage}</p>}
                   <button type="button" className="btn btn-primary" onClick={handleBack}>Back</button> &nbsp; &nbsp;
                   <button onClick={handleContact} className="btn btn-primary">Next</button>
                 </div>
                )}
                {step === 4 && (
                  <div>
                  <h3 className="theading">Upload Documents</h3>
                  <table>
                  <tr>
                    <td>
                      Technical Proposal:
                    </td>
                    <td>
                    <FileUpload 
                        s3Folder="proposals" 
                        onUploadComplete={(result) => handleUploadComplete('technical', result)} 
                      />
                    </td>
                  </tr>
                  <tr>
                    <td>
                      Financial Proposal:
                    </td>
                    <td>
                    <FileUpload 
                        s3Folder="proposals" 
                        onUploadComplete={(result) => handleUploadComplete('financial', result)} 
                      />
                    </td>
                  </tr>
                  <tr>
                    <td colSpan={2}></td>
                  </tr>
                  <tr>
                    <td><button type="button" className="btn btn-primary" onClick={handleBack}>Back</button></td>
                    <td><button type="submit" className="btn btn-primary" onClick={handleSubmit}>Save</button></td>
                  </tr>
                  <tr>
                    <td>
                  { 
                    isLoader &&
                        <Loader></Loader>
                    }
                    </td></tr>
                    {
                        isSuccess &&
                        <tr><td colSpan={2}>
                             <Alert variant="success">{err}</Alert>
                        </td></tr>
                     }
                     {
                       isError &&
                        <tr><td colSpan={2}>
                            <Alert variant="danger">{err}</Alert>
                        </td></tr>
                     }
                  </table>
                  </div>
                )}
        </div>
    );
  }
  export default PostBids;