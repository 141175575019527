import Dashboard from "../../pages/Dashboard";
import Homepage from "../../pages/HomePage";
import Home from "../../pages/Home";
import LoginPage from "../../pages/Login";
import PasswordReset from "../../pages/PasswordReset";
import SignupPage from "../../pages/Signup";
import About from '../../pages/About';
import Contact from '../../pages/Contact';
import UserLogOut from '../../pages/UserLogOut';
import Users from '../../pages/Users';
import Profile from '../../pages/Profile';
import TendersCat from "../../pages/TenderCategoriesList";
import TendersType from "../../pages/TenderTypeList";
import MyTenders from "../../pages/MyTenders";
import LiveTenders  from "../../pages/LiveTenders";
import Tenders from "../../pages/Tenders";
import SearchResults from "../../pages/SearchResults";
import PvtTenders from "../../pages/PvtTenders";
//import GovtTenders from "../../pages/GovtTenders";
import LatestTenders from "../../pages/LatestTenders";
import TendersbyDay from "../../pages/TendersbyDay";
import EditProfile from "../../pages/EditProfile";
import EditCompany from "../../pages/EditCompany";
import ChangePass from "../../pages/ChangePassword";
import Myblocklist from "../../pages/MyBlockList";
import MyBids from "../../pages/MyBids";
import AwardedBids from "../../pages/AwardedBidsPage";
import MyAccount from "../../pages/Account";
import Documents from "../../pages/MyDocuments";
import ExpiredBids from "../../pages/ExpiredBids";
import WithdrawnBids from "../../pages/Withdrawbids";
import BidsHistoryPage from "../../pages/BidsHistoryPage";
import NewTenderForm from "../../pages/NewTenderForm";
import EditTenderForm from "../../pages/EditTenderForm";
import VerifyEmail from "../../pages/VerifyEmail";
import FavTenders from "../../pages/FavTenders";
import TendersOrganizations from "../../pages/TendersOrganizations";
import TendersByLocation from "../../pages/TendersByLocation";
import TenderStatus from "../../pages/TenderStatus";
import CorrigendumSearchPage from "../../pages/CorrigendumSearchPage";
import BidAwardsPage from "../../pages/BidAwardsPage"
import ShortfallPage from "../../pages/ShortfallPage";
import ShortfallHistoryPage from "../../pages/ShortFallHistory";
import MyShortfallPage from "../../pages/MyShortfallPage";
import TCQueriesPage from "../../pages/TCQueriesPage";
import TCQHistoryPage from "../../pages/TCQueryHistoryPage";
import MyTCQueriesPage from "../../pages/MyTCqueriesPage";

class Route{
    constructor(id, path, component, heading, order) {
        this.id = id ? id : null;
        this.path = path ? path : null;
        this.component = component ? component : null;
        this.heading = heading ? heading : null;
        this.order = order ? order : null;
    }
}

export default class RoutesFactory{
    static getRoutes(isLoggedIn, role) {
        let routes = [];
        if(!isLoggedIn) {
            routes.push(new Route(1, '/', Home, 'Home', 1));
            routes.push(new Route(2, '/search-results/', SearchResults, 'Tenders Search', 4));
            routes.push(new Route(3, '/latest-tenders/', LatestTenders, 'Latest Tenders', 4));
            //routes.push(new Route(15, '/govt-tenders/', GovtTenders, 'Government Tenders', 4));
            //routes.push(new Route(5, '/pvt-tenders/', PvtTenders, 'Private Tenders', 4));
            routes.push(new Route(6, '/login', LoginPage, 'Login', 1));
            routes.push(new Route(7, '/signup', SignupPage, 'Signup', 1));
            routes.push(new Route(8, '/about', About, 'About', 3));
            routes.push(new Route(9, '/contact', Contact, 'Contact', 3));
            routes.push(new Route(10, '/passwordreset', PasswordReset, 'Password Reset', 3));
            routes.push(new Route(11, '/verifyemail/', VerifyEmail, 'Verify Email', 3));
            routes.push(new Route(12, '/tenders-by-organizations/', TendersOrganizations, 'Tenders by Organizations', 3));
            routes.push(new Route(13, '/tenders-by-location/', TendersByLocation, 'Tenders by Location', 3));
            routes.push(new Route(14, '/tenders-status/', TenderStatus, 'Tenders Status', 3));
            routes.push(new Route(15, '/corrigendum/', CorrigendumSearchPage, 'Corrigendum Search', 3));
            routes.push(new Route(16, '/bid-awards/', BidAwardsPage, 'Result of Tenders', 3));
        } else {
            if(role=='admin')
            {
                routes.push(new Route(10, '/dashboard', Dashboard, 'Dashboard', 1));
                routes.push(new Route(11, '/users', Users, 'Users', 1));
                routes.push(new Route(12, '/tenders', Tenders, 'Tenders', 1));
                routes.push(new Route(13, '/search-results/', SearchResults, 'Tenders', 4));
                routes.push(new Route(14, '/latest-tenders/', LatestTenders, 'Latest Tenders', 4));
                //routes.push(new Route(15, '/govt-tenders/', GovtTenders, 'Government Tenders', 4));
                //routes.push(new Route(16, '/pvt-tenders/', PvtTenders, 'Private Tenders', 4));
                routes.push(new Route(17, '/tenderscat', TendersCat, 'Tender Categories', 1));
                routes.push(new Route(18, '/tenderstype', TendersType, 'Tender Types', 1));
                routes.push(new Route(19, '/profile', Profile, 'Profile', 1));
                routes.push(new Route(20, '/about', About, 'About', 3));
                routes.push(new Route(21, '/contact', Contact, 'Contact', 3));
                routes.push(new Route(22, '/account', MyAccount, 'Account', 2));
                routes.push(new Route(23, '/editprofile', EditProfile, 'Edit Profile', 2));
                routes.push(new Route(24, '/editcompany', EditCompany, 'Edit Company', 2));
                routes.push(new Route(25, '/documents', Documents, 'Documents', 2));
                routes.push(new Route(26, '/changepass', ChangePass, 'Change Password', 2));
                routes.push(new Route(27, '/logout', UserLogOut, 'Log Out', 2));
                routes.push(new Route(28, '/withdrawn-bids', WithdrawnBids, 'Withdrawn Bids', 3));
                routes.push(new Route(29, '/bids-history', BidsHistoryPage, 'Bids History', 3));
                routes.push(new Route(30, '/livetenders', LiveTenders, 'Live Tenders', 3));
                routes.push(new Route(31, '/tenders-by-organizations/', TendersOrganizations, 'Tenders by Organizations', 3));
                routes.push(new Route(32, '/tenders-by-location/', TendersByLocation, 'Tenders by Location', 3));
                routes.push(new Route(33, '/tenders-status/', TenderStatus, 'Tenders Status', 3));
                routes.push(new Route(34, '/corrigendum/', CorrigendumSearchPage, 'Corrigendum Search', 3));
                routes.push(new Route(35, '/bid-awards/', BidAwardsPage, 'Result of Tenders', 3));
            }
            else
            {
                routes.push(new Route(10, '/homepage', Homepage, 'Home', 1));
                routes.push(new Route(11, '/search-results/', SearchResults, 'Tenders Search', 4));
                routes.push(new Route(12, '/latest-tenders/', LatestTenders, 'Latest Tenders', 4));
                //routes.push(new Route(13, '/govt-tenders/', GovtTenders, 'Government Tenders', 4));
                //routes.push(new Route(14, '/pvt-tenders/', PvtTenders, 'Private Tenders', 4));
                routes.push(new Route(15, '/profile', Profile, 'Profile', 1));
                routes.push(new Route(16, '/about', About, 'About', 3));
                routes.push(new Route(17, '/contact', Contact, 'Contact', 3));
                routes.push(new Route(18, '/mytenders', MyTenders, 'My Tenders', 3));
                routes.push(new Route(18, '/favorite-tenders', FavTenders, 'Favorite Tenders', 3));
                routes.push(new Route(19, '/myblocklist', Myblocklist, 'My Block List', 3));
                routes.push(new Route(20, '/my-bids', MyBids, 'My Bids', 3));
                routes.push(new Route(21, '/expired-bids', ExpiredBids, 'Expired Bids', 3));
                routes.push(new Route(22, '/editprofile', EditProfile, 'Edit Profile', 2));
                routes.push(new Route(23, '/account', MyAccount, 'Account', 2));
                routes.push(new Route(24, '/documents', Documents, 'Documents', 2));
                routes.push(new Route(25, '/editcompany', EditCompany, 'Edit Company', 2));
                routes.push(new Route(26, '/changepass', ChangePass, 'Change Password', 2));
                routes.push(new Route(27, '/logout', UserLogOut, 'Log Out', 2));
                routes.push(new Route(28, '/withdrawn-bids', WithdrawnBids, 'Withdrawn Bids', 3));
                routes.push(new Route(29, '/bids-history', BidsHistoryPage, 'Bids History', 3));
                routes.push(new Route(30, '/livetenders', LiveTenders, 'Live Tenders', 3));
                routes.push(new Route(31, '/tender/post', NewTenderForm, 'Create a New Tender', 3));
                routes.push(new Route(30, '/awarded-bids', AwardedBids, 'Awarded Bids', 3));
                routes.push(new Route(31, '/tenders-by-organizations/', TendersOrganizations, 'Tenders by Organizations', 3));
                routes.push(new Route(32, '/tenders-by-location/', TendersByLocation, 'Tenders by Location', 3));
                routes.push(new Route(33, '/tenders-status/', TenderStatus, 'Tenders Status', 3));
                routes.push(new Route(34, '/corrigendum/', CorrigendumSearchPage, 'Corrigendum Search', 3));
                routes.push(new Route(35, '/bid-awards/', BidAwardsPage, 'Result of Tenders', 3));
                routes.push(new Route(36, '/shortfall-requests/', ShortfallPage, 'Shortfall requests', 3));
                routes.push(new Route(37, '/shortfall-requests-history/', ShortfallHistoryPage, 'Shortfall History', 3));
                routes.push(new Route(38, '/my-shortfall-requests/', MyShortfallPage, 'My Shortfall Requests', 3));
                routes.push(new Route(39, '/techno-commercial-queries/', TCQueriesPage, 'Techno Commercial Queries', 3));
                routes.push(new Route(40, '/techno-commecial-queries-history/', TCQHistoryPage, 'Techno Commercial Queries History', 3));
                routes.push(new Route(40, '/my-techno-commecial-queries/', MyTCQueriesPage, 'Techno Commercial Queries', 3));
                
            }
        }
        return routes;
    }
}