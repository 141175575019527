import React, { useState, useEffect } from "react";
import { useNavigate, useHistory } from "react-router-dom";
import { Link } from 'react-router-dom';
import { Col, Container, Row } from "react-bootstrap";
import "bootstrap/dist/css/bootstrap.min.css";
import Tendershome from "../components/tenders/tenderslisthome";
import Corrigendumhome from "../components/tenders/corrigendumlisthome";
import Sidebar from "../components/users/frontpagesidebar"

const Home = () => {
  const token = sessionStorage.getItem('token');
  const [userType, setUserType] = useState(sessionStorage.getItem('type') || 'bidder');
  const navigate = useNavigate();
  const role = sessionStorage.getItem('role');

  if(token) {
      if (role === 'admin') {
        navigate("/dashboard");
    } else if (role === 'user') {
        navigate("/homepage");
    }
  }

  document.title = "Home | ConnectIND"; 
  
    return (
    <main role="main">
    <Container style={{background: "#fff"}}>
    <Row className='center'>
    </Row>
      <Row>
      <Col md={3} className='home'>
        <Sidebar />
      </Col>
      <Col md={7} className='mainarea'>
        <h1>Welcome to ConnectIND</h1>
        <p>ConnectedIND enables users find relevant tenders and submit the bids online. Search for Tenders, Corrigendums, and other relevant details.</p>
        <h3>Latest Tenders</h3>
        <Tendershome />
        <h3>Latest Corrigendum</h3>
        <div>
        <Corrigendumhome />
        </div>
      </Col>
      <Col md={2} className='home'>

      {!token && (<div className="ctrlbox">
        <ul className="list-group">
        <li className="list-group-item"><Link to="/login" className="nav-link">Click Here to Login</Link></li>
        </ul>
        <ul className="ln">
          <li><Link to="/signup">Online Bidder Enrollment</Link></li>
          <li><Link to="/passwordreset">generate/ Forgot Password?</Link></li>
        </ul>
        </div>)}
        <div className="ctrlbox ctrlsearch">
        <strong>Tender Search</strong>
        <div>
          <form action="/search-results/">
          <input type="text" className="box" name="q" />
          <input type="submit" className="btnfloat-end btn btn-primary btn-sm" value="Search" />
          </form>
        </div>
        <a href="/search-results/">Advanced Search</a>
        </div>
        <div id="menu4">
        <ul>
          <li>
            <a href="/" target="_self">Help For Contractors</a>
          </li>
          <li>
            <a href="/" target="_blank">Guidelines for Hassle Free Bid Submission</a>
                                      
          </li>
          <li>
            <a href="/" target="_self">Information About DSC</a>																					
          </li>
          <li>
            <a href="/" target="_self">FAQ</a>																					
          </li>
          <li>
            <a href="/" target="_self">Feedback</a>																					
          </li>
          <li>
            <a href="/" target="_self">Bidders Manual Kit</a>																					
          </li>
          </ul>
				</div>  
      </Col>
      </Row>
    </Container>
    </main>
    );
}
 
export default Home;