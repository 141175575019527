import React, { useEffect, useState } from "react";
import { useNavigate, useParams } from "react-router-dom";
import { Modal, Button } from "react-bootstrap";
import { axiosRequest } from "../../api";
import Loader from '../App/loader';

const Mybids = (props) => {

  const token = sessionStorage.getItem('token');
  const [users, setData] = useState([]);
  const user = sessionStorage.getItem('user');
  const [isLoader, setLoader] = useState(false);
  const navigate = useNavigate();
  const [show, setShow] = useState(false);
  const [err, setErr] = useState([]);
  const [refreshKey, setRefreshKey] = useState(0);

  var udata = JSON.parse(user);
  var userid = udata[0].id;
  var companyid = udata[0].company_user.id;

  if (token.exp * 1000 < Date.now()) {
    console.log('Expired');
  }

  const config = {
    headers: { Authorization: `Bearer ${token}`, userid: `${userid}` }
  };
  const data = {
  }

  useEffect(() => {
    setLoader(true);
    axiosRequest.get('bids/company/'+companyid, data, config)
      .then(function (response){
        setLoader(false);
        setData(response.data)
      })
      .catch(function (error) {
        if(error.response.data.message=='Invalid Token')
        {
            navigate("/logout");
        }
      });
  }, [refreshKey]);

  const withdraw = (e) => {
    const choice = window.confirm(
      "Do you really want to withdraw this proposal?"
    );
    if (choice) {
      
    const config1 = {
      headers: { Authorization: `Bearer ${token}`, userid: `${userid}` }
    };
    setLoader(true);
    axiosRequest.get('bids/withdraw/'+e, config1, config1)
      .then(function (response){
        setLoader(false);
        if(response.status==200)
        {
          if (response.data.msg == 'Success') {
            setRefreshKey(oldKey => oldKey +1)
          }
          else
          {
            setErr("Your proposal couldn't bee withdrawn");
            setShow(true);
          }
        }
        else
        {
          setErr("Your proposal couldn't bee withdrawn1");
          setShow(true);
        }
      })
      .catch(function (error) {
        console.log(error);
        alert(error);
      });
    
    }
  }

  const publish = (e) => {
    const choice = window.confirm(
      "Do you really want to publish this bid?"
    );
    if (choice) {
      
    const config1 = {
      headers: { Authorization: `Bearer ${token}`, userid: `${userid}` }
    };
    setLoader(true);
    console.log('bids/publish/'+e);
    axiosRequest.get('bids/publish/'+e, config1, config1)
      .then(function (response){
        setLoader(false);
        if(response.status==200)
        {
          if (response.data.msg == 'Success') {
            setRefreshKey(oldKey => oldKey +1)
          }
          else
          {
            setErr("Your bid could n't be published");
            setShow(true);
          }
        }
        else
        {
          setErr("Your bid couldn't be published");
          setShow(true);
        }
      })
      .catch(function (error) {
        console.log(error);
        alert(error);
      });
    
    }
  }

  const formatDate = (dateString) => {
    const date = new Date(dateString);
    return date.toLocaleString('en-US', {
      year: 'numeric',
      month: 'long',
      day: 'numeric',
      hour: 'numeric',
      minute: 'numeric',
      second: 'numeric',
      hour12: true
    });
  };

  const usrView = (e) => {
    navigate("/bids/view/"+e);
    window.location.pathname = '/bids/view/'+e;
  }

  const usrEdit = (e) => {
    navigate("/bids/edit/"+e);
    window.location.pathname = '/bids/edit/'+e;
  }

  if(!token) {
    navigate("/login");
    window.location.pathname = '/login';
  }

  const handleClose = () => setShow(false);

  const getStatusText = (status) => {
    switch (status) {
      case 1:
        return 'Draft';
      case 2:
        return 'Live';
      case 3:
        return 'Technical Accepted';
      case 4:
        return 'Financial Accepted';
      case 5:
        return 'Awarded';
      case 6:
        return 'Withdrawn';
      default:
        return 'Unknown';
    }
  };

  return (
    <div>
      <table className="table table-striped table-bordered">
        <thead>
          <tr>
            <th>Title</th>
            <th>Technical Document</th>
            <th>Financial Document</th>
            <th>Last Updated</th>
            <th>Status</th>
            <th></th>
            <th></th>
          </tr>
        </thead>
        <tbody>
          {users && users.map(user =>
            <tr key={user.id}>
              <td>{user.cover}</td>
              <td><a href={user.technical_doc}>Download</a></td>
              <td><a href={user.financial_doc}>Download</a></td>
              <td>{formatDate(user.updatedAt)}</td>
              <td>{getStatusText(user.status)}</td>
              <td>
                <a href="#" onClick={usrEdit.bind(this, user.id)}>Edit</a> | 
                <a href="#" onClick={usrView.bind(this, user.id)}>View</a>
              </td>
              <td>
                {user.status === 1 ? 
                  <a href="#" onClick={publish.bind(this, user.id)}>Publish Bid</a> :
                  <a href="#" onClick={withdraw.bind(this, user.id)}>Withdraw</a>
                }
              </td>
            </tr>
          )}
          { 
            isLoader &&
            <Loader></Loader>
          }
        </tbody>
      </table>
      <Modal show={show} onHide={handleClose}>
        <Modal.Header closeButton>
          <Modal.Title>Alert</Modal.Title>
        </Modal.Header>
        <Modal.Body>{err}</Modal.Body>
        <Modal.Footer>
          <Button variant="secondary" onClick={handleClose}>
            Close
          </Button>
        </Modal.Footer>
      </Modal>
    </div>
  );
}

export default Mybids;