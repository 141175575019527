import React, { useEffect, useState } from "react";
import { useNavigate, useParams, Link } from "react-router-dom";
import { Button, Col, Container, Form, Alert } from "react-bootstrap";
import { axiosRequest } from "../../api";
import baseUrl from '../../classes/configs/config';
import Loader from '../App/loader';

const TenderDetail = (props) => {
  const token = sessionStorage.getItem('token');
  const navigate = useNavigate();
  const user = sessionStorage.getItem('user');
  const [data, setUser] = useState([]);
  const [city, setCity] = useState([]);
  const [cat, setCat] = useState([]);
  const [company, setCompany] = useState([]);
  const [isOwner, setisOwner] = useState(false);
  const [isGov, setisGov] = useState(false);
  const [bidsurl, setBidsURL] = useState(false);
  const [applyurl, setApplyURL] = useState(false);
  const [islogin, setLogin] = useState(false);
  const [blocked, setIfBlocked] = useState(false);
  const [users, setData] = useState([]);
  const [isLoader, setLoader] = useState(false);
  const [corrigendums, setData1] = useState([]);
  const [isFavorited, setIsFavorited] = useState(false);
  const [isExpired, setisExpired] = useState(true);

  var userid = 0;  

  if(user)
  {
    var udata = JSON.parse(user);
    userid = udata[0].id;
  }

  var {id} = useParams();
  if(!id)
  {
    id = 1;
  }
  const config = {
    headers: { Authorization: `Bearer ${token}`, userid: `${userid}` }
  };

const userdata = {id}

useEffect(() => {
  setLoader(true);
  if(user)
  {
    setLogin(true);
  }
  if(id)
  {
    axiosRequest.get('/tenders/view/'+id, userdata, config)
      .then(function (response){
        setCity(response.data.tenders_city.name);
        setCat(response.data.tenders_categories.name);
        setCompany(response.data.tender_company);
        setUser(response.data);
        setBidsURL('/tenders/bids/'+id);
        setApplyURL('/tender/'+id+'/apply');
        
        axiosRequest.get('tenders/documents/'+id, data, config)
        .then(function (response){
          setData(response.data)
          setLoader(false);
        })

        axiosRequest.get('tenders/corrigendum/'+id, data, config)
        .then(function (response){
          setData1(response.data)
          setLoader(false);
        })
        .catch(function (error) {
          if(error.response.data.message=='Invalid Token')
          {
              navigate("/logout");
          }
        });

        if(response.data.userid == userid)
        {
          setisOwner(true);
        }
        if(response.data.isGov==1)
        {
          setisGov(true);
        }

        // check if tender is already expired
        const currentDate = new Date();
        const bidSubmissionClosingDate = new Date(response.data.bid_submission_closing_date);

        if (bidSubmissionClosingDate < currentDate) {
          setisExpired(true);
        }
        else
        {
          setisExpired(false)
        }

      // check if already a favorite
      axiosRequest.get('tenders/favorite/check-favourite/'+id, config)
        .then(function (response){
          if(response.data.msg == 'Yes')
            setIsFavorited(true);
          else
            setIsFavorited(false);
        })
      
      if(response.data && userid!=0)
      {
      axiosRequest.get('/blocklist/tender/user/'+response.data.userid, config)
      .then(function (response){

        if(response.data.msg == 'Blocked')
        {
          setIfBlocked(true);
        }
      })
      .catch(function (error) {
        console.log(error);
      });
    }

      })
      .catch(function (error) {
        console.log(error);
      });
  }
}, [])

  const handleFavoriteToggle = async () => {
    try {
      if (isFavorited) {
        // Remove from favorites
        await axiosRequest.post('/tenders/favorite/remove-favorite/'+id,'', config);
      } else {
        // Add to favorites
        await axiosRequest.post('/tenders/favorite/add/'+id,'', config);
      }
      // Toggle the favorite state
      setIsFavorited(!isFavorited);
    } catch (error) {
      console.error('Error updating favorite status:', error);
    }
  };

  const detail = {
    fontSize: "12px",
    fontFamily: "tahoma"
  };

  document.title = data.title+" | ConnectIND"; 
    return (
            <div style={detail}>
                {
                    blocked &&
                      <Alert variant="danger">You cannot post a bid for this tender. You have been blocked by the <strong>{company.name}.</strong></Alert>
                } 

              {data.status==4  && (
                  <Alert variant="primary">
                      <p>This tender has already been awarded.</p>
                  </Alert>
                )}
              {data.status==3  && (
                  <Alert variant="primary">
                      <p>This tender has been Cancelled.</p>
                  </Alert>
                )}  
                <h3 className="dtheading">Basic Details</h3>
                <table className="tenders">
                <tbody>
                    <tr><td><strong>Title</strong></td><td colSpan={3}>{data.title}</td></tr>
                    <tr><td><strong>Work Description</strong></td><td colSpan={3}>{data.description}</td></tr>
                    <tr><td><strong>Tender Reference No.</strong></td><td colSpan={3}>{data.reference_no}</td></tr>
                    <tr><td><strong>Tender Value</strong></td><td colSpan={3}>₹{data.tender_value}</td></tr>
                    <tr>
                      <td><strong>Type:</strong></td><td>{data.type}</td>
                      <td><strong>Category:</strong></td><td>{cat}</td>
                    </tr>
                    <tr>
                      <td><strong>Location:</strong></td><td>{city}</td>
                      <td><strong>Pin Code:</strong></td><td>{data.pincode}</td>
                    </tr>
                </tbody>
                </table>
                <h3 className="dtheading">Tender Fee Details</h3>
                <table className="tenders">
                <tbody>
                    <tr>
                      <td><strong>Tender Fee:</strong></td><td>₹{data.tender_fee}</td>
                      <td></td><td></td>
                    </tr>
                    <tr>
                      <td className="col"><strong>Fee Payable To:</strong></td><td>{data.fee_payable_to}</td>
                      <td className="col"><strong>Fee Payable At:</strong></td><td>{data.fee_payable_at}</td>
                    </tr>
                    <tr>
                      <td className="col"><strong>EMD Amount:</strong></td><td>₹{data.emd_amount}</td>
                      <td className="col"><strong>Emd Fee Type</strong></td><td>{data.emd_fee_type}</td>
                    </tr>
                    <tr>
                      <td className="col"><strong>EMD Fee Payable To:</strong></td><td>{data.emd_payble_to}</td>
                      <td className="col"><strong>EMD Fee Payable At:</strong></td>{data.emd_payble_at}<td></td>
                    </tr>
                </tbody>
                </table>
                <h3 className="dtheading">Important Dates</h3>
                <table className="tenders">
                <tbody>
                    <tr>
                      <td className="col"><strong>Published Date:</strong></td><td>{data.pubished_date}</td>
                      <td className="col"><strong>Bid Opening Date:</strong></td><td>{data.bid_opening_date}</td>
                    </tr>
                    <tr>
                      <td className="col"><strong>Bid Submission Start Date:</strong></td><td>{data.bid_submission_start_date}</td>
                      <td className="col"><strong>Bid Submission End Date:</strong></td><td>{data.bid_submission_closing_date}</td>
                    </tr>
                </tbody>
                </table>
                <h3 className="dtheading">Tender Documents</h3>
                {
                islogin &&
                <table className="table table-striped table-bordered">
                <thead>
                    <tr>
                        <th>Type</th>
                        <th>Title</th>
                        <th>Pubished Date</th>
                        <th></th>
                    </tr>
                </thead>
                <tbody>
                    {users && users.map(user =>{
                      const durl = baseUrl+'/documents/'+user.url;
                        return(<tr key={user.id}>
                            <td>{user.tender_documents_type.name}</td>
                            <td>{user.name}</td>
                            <td>{user.description}</td>
                            <td>
                                <a href={durl} target="_blank">Download</a>
                            </td>
                        </tr>)
                    })}
                    { 
                    isLoader &&
                        <Loader></Loader>
                    }
                </tbody>
              </table>
              }
              {
                !islogin &&
                <div class="alert alert-danger" role="alert">
                    <h5>Login to see tender documents</h5>
                  </div>
              }
              <h3 className="dtheading">Tender Corrigendum</h3>
                {
                islogin &&
                <table className="table table-striped table-bordered">
                <thead>
                    <tr>
                        <th>Type</th>
                        <th>Title</th>
                        <th>Pubished Date</th>
                        <th></th>
                    </tr>
                </thead>
                <tbody>
                    {corrigendums && corrigendums.map(corrigendum =>{
                        return(<tr key={corrigendum.id}>
                            <td>{corrigendum.type}</td>
                            <td>{corrigendum.title}</td>
                            <td>{corrigendum.createdAt}</td>
                            <td>
                                <a href={corrigendum.document_url} target="_blank">Download</a>
                            </td>
                        </tr>)
                    })}
                    { 
                    isLoader &&
                        <Loader></Loader>
                    }
                </tbody>
              </table>
              }
                <h3 className="dtheading">Tender Inviting Company</h3>
                <table className="tenders">
                <tbody>
                    <tr>
                      <td className="col"><strong>Company:</strong></td><td>{company.name}</td>
                      <td></td><td></td>
                    </tr>
                    <tr>
                      <td className="col"><strong>Registered Address:</strong></td><td>{company.registered_address}</td>
                      <td></td><td></td>
                    </tr>
                </tbody>
                </table>
                
                <table>
                <tbody>
                    <tr>
                    {
                    !isExpired && isOwner && !isGov &&
                  <td><Link to={bidsurl} className="btn btn-primary">See All Bids</Link></td>
                    }
                    {
                   !isExpired && !isOwner && !isGov && !blocked && islogin && data.status==2 && 
                      <td><Link to={applyurl} className="btn btn-primary">Place a Bid</Link></td>
                    }
                    {
                    !isExpired && !islogin && data.status==2 &&
                      <td><Link to="/login" className="btn btn-primary">Login to make a Bid</Link></td>
                    }
                    {
                    !isExpired &&  isGov &&
                      <td><Link to={data.url} className="btn btn-primary">See More Details</Link></td>
                    } 
                    {
                    !isExpired && !isOwner && islogin && 
                      <td><button onClick={handleFavoriteToggle} className="btn btn-primary">
                      {isFavorited ? 'Remove from Favorites' : 'Add to Favorites'}
                    </button></td>
                    }
                    {
                     isExpired &&
                      <td>
                        <Alert variant="danger">This tender is no longer accepting bids</Alert></td>
                    }
                    </tr>
                </tbody>
                </table>
            </div>
    );
  }
  export default TenderDetail;