import React, { useEffect, useState } from "react";
import { useNavigate, useParams, Link } from "react-router-dom";
import { Alert } from "react-bootstrap";
import { axiosRequest } from "../../api";
import Loader from '../App/loader';

const BidsDetail = (props) => {
  const token = sessionStorage.getItem('token');
  const navigate = useNavigate();
  const user = sessionStorage.getItem('user');
  const [data, setUser] = useState([]);
  const [profileURL, setProfile] = useState([]);
  const [company, setCompany] = useState([]);
  const [isLoader, setLoader] = useState(false);
  const [allBids, setBidsURL] = useState([]);
  const [alert, setAlert] = useState('secondary');
  const statusMap = {
    1: 'Draft',
    2: 'Live',
    3: 'Technical Accepted',
    4: 'Financial Accepted',
    5: 'Awarded',
    6: 'Withdrawn',
  };

  document.title = "Bids Details | ConnectIND"; 
  
  var userid = 0;  

  if(user)
  {
    var udata = JSON.parse(user);
    userid = udata[0].id;
  }

  var {id} = useParams();
  if(!id)
  {
    id = 1;
  }
  const config = {
    headers: { Authorization: `Bearer ${token}`, userid: `${userid}`  }
};

const userdata = {id}

useEffect(() => {
  setLoader(true);
  if(id)
  {
    console.log('/bids/detail/'+id);
    axiosRequest.get('/bids/detail/'+id, userdata, config)
      .then(function (response){
        setCompany(response.data.bid_company);
        setUser(response.data);
        
        setProfile("/profile/"+response.data.bid_company.userid);
        setBidsURL("/tenders/bids/"+response.data.tenderid);
        setLoader(false);
      })
      .catch(function (error) {
        console.log(error);
      });
  }
}, [])
  
    return (
            <div className="">
                <div className="float-start">
                <nav aria-label="breadcrumb">
                  <ol class="breadcrumb">
                    <li class="breadcrumb-item"><a href="/homepage">Home</a></li>
                    <li class="breadcrumb-item"><Link to={allBids}>All Bids</Link></li>
                    <li class="breadcrumb-item" aria-current="page">Bid Detail</li>
                  </ol>
                </nav>
                </div>
                <br />
                <br />
                <strong class="pgheading">Bid: Status</strong>
                <Alert variant={alert}>
                <h3>{statusMap[data.status]}</h3> 
                </Alert>
                <br />
                <table className="tenders">
                <tbody>
                    <tr><td><strong>Cover</strong></td><td colSpan={3}>{data.cover}</td></tr>
                    <tr><td><strong>Propsal</strong></td><td colSpan={3}><a href={data.proposal} target="_blank">{data.proposal}</a></td></tr>
                    <tr>
                      <td><strong>Created At:</strong></td><td>{data.createdAt}</td>
                      <td><strong>updated At:</strong></td><td>{data.updateAt}</td>
                    </tr> 
                </tbody>
                </table>
                { 
                    isLoader &&
                        <Loader></Loader>
                    }
                <h3 className="theading">Company Details</h3>
                <table className="tenders">
                <tbody>
                    <tr>
                      <td><strong>Company:</strong></td><td><Link to={profileURL}>{company.name}</Link></td>
                      <td></td><td></td>
                    </tr>
                    <tr>
                      <td><strong>Registered Address:</strong></td><td>{company.registered_address}</td>
                      <td></td><td></td>
                    </tr>
                    <tr>
                      <td><strong>Registration Number:</strong></td><td>{company.regisration_number}</td>
                      <td></td><td></td>
                    </tr>
                </tbody>
                </table>
        </div>
    );
  }
  export default BidsDetail;